@tailwind base;
@tailwind components;
@tailwind utilities;

.card-container {
    perspective: 1000px;
  }
  
  .card {
    width: 100%;
    height: 300px;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    position: relative;
  }
  
  .card.flipped {
    transform: rotateY(180deg);
  }
  
  .front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .back {
    transform: rotateY(180deg);
  }
  